@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.drop-down-card {
  display: flex;
  height: 5rem;
  position: relative;

  @include respond-below("laptop") {
    height: fit-content;
  }
}

.drop-down-card-container {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  width: 95%;

  @include respond-below("laptop") {
    flex-wrap: wrap;
  }

  @include respond-below("tablet") {
    flex-direction: column;
  }
}

.mobile-hidden-container{
  @include respond-below("tablet") {
    display: none;
  }
}

.airport-drop-down-button {
  // width: 20rem;

  @include respond-below("desktop") {
    width: 95%;
  }
  @include form-field-name;

    @include respond-below("tablet") {
      width: 90%;
    }
}

.type-drop-down-button {
  // width: 12rem;
  @include form-field-name;
  white-space: nowrap;
  // overflow: hidden;

  @include respond-below("desktop") {
    width: 90%;
  }

  @include respond-below("tablet") {
    width: 90%;
    // font-size: small;
  }
}

.form-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  // width: 17.5%;

  @include respond-between("phone","laptop") {
    padding-bottom: 1rem;
  }

  @include respond-below("tablet") {
    width: 100%;
  }
}

.report-form-field{
    width: 13.5%;
  @include respond-below("laptop") {
    width: 25%;
  }
  @include respond-below("tablet") {
    width: 100%;
  }
}

.type-form-field{
    width: 8%;
  @include respond-below("laptop") {
    width: 25%;
  }
  @include respond-below("tablet") {
    width: 100%;
  }
}


.airport-form-field{
  @include respond-above("tablet") {
    width: 30%;
  }
  @include respond-between("tablet","laptop") {
    width: 55%;
    margin-right: 15%;
  }
}

.date-form-field{
  @include respond-between("tablet","laptop") {
    width: 25%;
  }
}

.form-label-text {
  @include form-field-name;
  font-weight: bold;
  width: 30%;
  padding: 0.3rem;
}

.datetime-input-button:hover {
  cursor: pointer;
}

.datetime-input-button {
  display: flex;
  justify-content: flex-end;
  height: 2rem;
  // width: 95%;
}

.datetime-report-dropdown {
  @include respond-below("tablet") {
    width: 90%;
  }
}
.date-pointer {
  @include form-field-name;
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  // width: 15rem;
  text-align: left;
  padding: 0.3rem;

  @include respond-below("laptop") {
    width: 100%;
  }
}

.disabled-date-pointer {
  border-radius: 0.3rem;
  background: #f0f0f0;
  border: none;
  width: 100%;

  @include respond-below("tablet") {
    width: 100%;
  }
}

.date-pointer:hover {
  cursor: pointer !important;
}

.open-calendar-program-setup {
  font-size: 1.25rem;
  cursor: pointer !important;
  background: none;
  border: none;
  color: black;
  font-family: "Lato", sans-serif;
  position: absolute;
  margin-left: 16rem;
  margin-top: 0.2rem;
  padding-right: 0.5rem;
}

.disabled-calendar {
  @extend .open-calendar-program-setup;
  color: #dcdada;
}

.calendar-container {
  position: absolute;
  top: 25%;
  left: 40%;
  right: 40%;
  background-color: white;
  padding: 1rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;
  overflow-y: scroll;
  width: 22rem;
  z-index: 9999;
  border-style: solid;
  border-width: thick;
  border-color: #010b4b;

  @include respond-below("tablet") {
    left: 0%;
    width: 89%;
  }
}

.reports-calendar{
  top: -130%;
  @include respond-below("tablet") {
    top: 25%;
  }
}

.rs-dropdown-menu {
  // @include label-text;
  font-size: inherit;
}

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  font-size: inherit;
}

// .disabled-button {
//   .rs-dropdown-toggle,
//   .rs-dropdown-toggle.rs-btn {
//     background: #f0f0f0;
//     border: none;
//   }
//   .rs-dropdown-toggle-caret {
//     color: #dcdada;
//   }
// }

.disabled{
    pointer-events: none;

    .rs-dropdown-toggle,
    .rs-dropdown-toggle.rs-btn {
      background: #f0f0f0;
      border: none;
    }
    .rs-dropdown-toggle-caret {
      color: #dcdada;
    }
}

// input[type="submit"] {
//   @include body-text;

//   border-radius: 1rem; //
//   border: none;
//   padding: 0.4rem 3rem 0.4rem 3rem; //
//   color: white;
//   font-family: "Lato", sans-serif;
//   font-weight: bold;
//   margin-top: 2rem;
// }

.events-dropdown-submit{
  border-radius: 1rem!important; 
  padding: 0.4rem 0rem!important; 
  @include respond-between("tablet","laptop") {
    margin-bottom: 3rem;
  }
}

.reports-search-button{
  @include respond-below("tablet") {
    align-self: center;
    margin-right: 10%;
    padding-bottom: 1rem;
  }
}

.collapse-caret{
  position: absolute;
  right: 2%;
  bottom: 2%;

  @include respond-above("tablet") {
    display: none;
  }
}

.expand-caret{
  position: absolute;
  right: 2%;
  bottom: 20%;
  @include respond-above("tablet") {
    display: none;
  }
}

.expand-icon{
  transform: scaleY(-1);
}

.filter-search-contrainer{
  margin-left: 1.5rem;
  padding: 0.5rem;
  font-weight: bold;
  font-size: large;
  display: flex;

  @include respond-above("tablet") {
    display: none;
  }
}

.reports-search-button{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 4rem;
    width: 10%;
  
    @include respond-below("tablet") {
      width: 25%;
    }
}

.datetime-report-dropdown{
  width: 100%;
  @include respond-below("tablet") {
    width: 90%;
  }
}