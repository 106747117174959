@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.program-setup-container {
  display: flex;
  flex-direction: column;
  background: white;
  width: 68%;
  margin-left: 18%;
  padding-right: 2.5rem;
  padding-top: 1rem;
}

.program-setup-form-container {
  display: flex;
  flex-direction: column;
  margin-left: 2.5rem;
  border: 2px solid #707070;
  padding-top: 1.5rem;
  margin-bottom: 3rem;
}

.program-setup-form-label-text {
  @include form-field-name;

  padding: 0.3rem;
}

.program-setup-form-fields {
  display: flex;
  height: 2rem;
}

.calendar-container {
  position: absolute;
  top: 25%;
  left: 40%;
  right: 40%;
  background-color: white;
  padding: 1rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;
  overflow-y: scroll;
  width: 22rem;
  z-index: 9999;
  border-style: solid;
  border-width: thick;
  border-color: #010b4b;
}

.save-program-button {
  @include body-text;
  background-color: #010b4b;
  border-radius: 1.5rem;
  border: none;
  padding: 0.7rem 4rem 0.7rem 4rem;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-bottom: 1rem;
  width: max-content;
  align-self: center;
  cursor: pointer;
  margin-top: -5px;
}

.save-program-button-disabled {
  @extend .save-program-button;
  background-color: #dcdada;
  cursor: default;
}

.program-setup-header {
  text-align: center;
  padding-bottom: 2rem;
}

.program-setup-select-time {
  margin-left: 1rem;
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  overflow-y: scroll;
  height: fit-content;
  min-height: -webkit-fill-available;
  width: 4rem;
  position: relative;
  cursor: pointer;
}

.program-setup-select-time-focused {
  @extend .program-setup-select-time;
  z-index: 9;
}
.program-setup-select-time-disabled {
  @extend .program-setup-select-time;
  border-color: #f0f0f0;
  background-color: #f0f0f0;
  cursor: default;
}

.program-setup-datetime-input-button{
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.program-setup-date-pointer {
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem; 
  width: 15rem;
  cursor: pointer !important;
}

.program-setup-date-disabled {
  @extend .program-setup-date-pointer;
  border-color: #f0f0f0;
  background-color: #f0f0f0;
  cursor: default !important;
}

.setup-description-text {
  width: 90% !important;
  height: 2.75rem;
  text-align: left;
  overflow-wrap: break-word;
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  resize: none;
}

.open-calendar-program-setup {
  font-size: 1.25rem;
  cursor: pointer !important;
  background: none;
  border: none;
  color: black;
  font-family: "Lato", sans-serif;
  position: absolute;
  margin-left: 16rem;
  margin-top: 0.2rem;
  padding-right: 0.5rem;
}
.calendar-program-setup-disabled {
  @extend .open-calendar-program-setup;
  color: #dcdada;
  cursor: default !important;
}
.program-setup-description{
  height: 5rem;
}

.setup-description-text-div {
  width: 85%;
}

.open-airport-rules-button {
  background-color: #010b4b;
  border-radius: 1.5rem;
  font-size: 1.1rem;
  border: none;
  padding: 0.7rem 3rem 0.7rem 3rem;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: max-content;
  align-self: center;
  cursor: pointer;
}

option {
  padding-bottom: 0.2rem;
}

.airports-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-radius: 0.3rem;
  width: 100%;
  margin-left: -3.5rem;
}

.airport-even {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  padding-left: 1rem;
  @include subtitle;
}

.airport-odd {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  padding-left: 1rem;
  @include subtitle;
}

.airport-none {
  background-color: white;
  text-align: center;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  font-style: italic;
}

.airport-last {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  padding-left: 1rem;
  @include subtitle;
}

.airport-last-gray {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  padding-left: 1rem;
  @include subtitle;
}

.airport-last-white {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  padding-left: 1rem;
  @include subtitle;
}

.airport-single {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 2px solid #707070;
  border-left-style: none;
  border-right-style: none;
  padding-left: 1rem;
  @include subtitle;
}

.airports-table {
  background-color: #f0f0f0;
  // background-color: #010b4b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 80%;
  border: 2px solid #707070;
  border-radius: 0.3rem;
}

.airport-selection-container {
  margin-left: 2.5rem;
  display: flex;
  // flex-direction: row;
}

.open-airport-rules-button-disabled {
  @extend .open-airport-rules-button;
  background-color: #dcdada;
  cursor: default;
}

.remove-rule {
  @include subtitle;
  margin-right: 1rem;
  color: #cb444a;
  border: none;
  background: none;
  font-weight: bold;
  cursor: pointer;
}

.trashcan-icon-setup {
  margin-right: 0.2rem;
}

.modify-rule {
  @extend .remove-rule;
  color: #ad6327;
}

.event-name-field {
  width: 20rem;
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem; 
}

.no-enddate-label{
  margin-left: 0.4rem;
}

.no-enddate-container {
  margin-left: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  vertical-align: middle;
}

.no-end-date{
  transform: scale(1.5);
}