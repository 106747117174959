@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.cancel-modal-close:hover {
  cursor: pointer;
}
.cancel-modal-close {
  @include small-text;
  background-color: #6e737c;
  color: white;
  font-weight: bold;
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 0.25rem;
  margin-right: 1rem;
}

.cancel-modal-confirm:hover {
  cursor: pointer;
}
.cancel-modal-confirm {
  @include small-text;
  background-color: #cb444a;
  color: white;
  font-weight: bold;
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 0.25rem;
  margin-left: 1rem;
}

.cancel-modal-buttons {
  display: flex;
  width: 15rem;
  justify-content: space-around;
  padding-top: 1rem;
  align-self: center;
}

h5 {
  @include form-title;
  padding: 0.5rem 1rem 0 1rem;
}

.cancel-form-label-text {
  width: 100%;
    padding: 0.25rem 0rem 0.25rem 0rem;
}

.admin-cancel-confirm-title {
  padding-bottom: 1rem;
}

.admin-cancel-reservation-button {
  cursor: pointer;
}
.admin-cancel-reservation-button {
  color: red;
  border: none;
  background: none;
}

.admin-cancel-icon {
  padding-right: 0.25rem;
}

.cancel-form-fields{
  word-break: break-word;
}