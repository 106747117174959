@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";


.change-page-button:hover {
  cursor: pointer;
}
.change-page-button {
  align-self: center;
  color: #003e7e;
  border: none;
  background: none;
  font-size: 1.75rem;
  margin: 0.5rem 2rem 0.5rem 2rem;
  padding: 0rem;
}

.change-page-button-disabled {
  @extend .change-page-button;
  color: #d5d5d5;
}

.page-label {
  align-self: center;
  text-align-last: center;
}
