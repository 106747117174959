@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.group-access-button:hover {
  cursor: pointer;
}

.group-access-button {
  border: 2px solid;
  border-color: #707070;
  border-radius: 0.3rem;
  padding: 0.1rem 0.2rem;
  margin-left: 1rem;
  margin-bottom: 0.2rem;

  @include respond-between("phone", "laptop") {
    font-size: 0.7rem;
  }

  @include respond-below("tablet") {
    width: 10rem;
  }
}

.group-access-body {
  flex-basis: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group-access-container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
