@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.user-group-toggle-container {
    display: flex;
    justify-content: center;

    @include respond-below("laptop") {
        justify-content: flex-start;
    }
}

.user-group-toggle {
    display: flex;
    width: 25%;
    background: white;
    flex-flow: nowrap;
    padding: 1rem;
    justify-content: space-around;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.125);
    border-radius: 0.4rem;

}

.toggle-button {
    @include form-title;
    outline: none;
    border: 0px;
    background: none;
    font-weight: bold;
}

.toggle-button-active {
    @extend .toggle-button;
    color: #2a4297;
    text-decoration: underline;
}

.toggle-button:hover {
    cursor: pointer;
}

.group-table-actions{
    width: 0%;
    padding: 0 2rem;
    text-wrap: nowrap;
}

.new-code{
    color: #227FF5;
}

.new-code:hover{
    cursor: pointer;
}

.new-code-icon{
    font-size: 1.25rem;
    padding-right: 0.25rem;
}
