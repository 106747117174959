@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.app-header {
  background: #003e7e;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerFooterHeight;
  padding: 0 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  @include respond-above("laptop") {
  > *:first-child,
  > *:last-child {
    flex: 100%;
  }
  }

  @include respond-below("tablet") {
    padding: 0 1rem;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  color: #ffffff;
  height: $headerFooterHeight;

  @include respond-above("laptop") {
    width: 50%;
  }

  @include respond-below("laptop") {
    width: 6rem;
  }

  &:hover {
    cursor: pointer;
    background: #15396c;
  }
}

.logo {
  max-width: 6.25rem;
  min-width: 4.75rem;
  width: 100%;
}

.faa-home-link {
  text-decoration: none;
}

.faa-name {
  @include body-text;
  font-weight: bold;
  padding-left: 0.75rem;
  max-width: 10rem;

  @include respond-below("laptop") {
    display: none;
  }

  a:link {
    text-decoration: none;
  }
}

.app-title {
  @include site-title;
  text-align: center;
  padding: 0 1rem;
  font-weight: normal;

  @include respond-below("tablet") {
    padding-bottom: 0.2rem;
  }

  //This is specifically for the iPhone 5
  @include respond-below("phone") {
    padding: 0.4rem;
    font-size: 1.1rem;
  }
}

.vertical-time-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include respond-above("laptop") {
    display: none;
  }
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include respond-below("laptop") {
    display: none;
  }
}

.current-time {
  background: #815c00;
}

.incrementing-time {
  @include label-text;
  text-align: right;
  padding: 0.25rem;
  margin: 0rem 0.25rem 0.25rem 0.25rem;

  @include respond-below("tablet") {
    margin: unset;
    text-align: center;
  }
}

.menu-container {
  display: flex;
  justify-content: flex-end;

  @include respond-above("laptop") {
    display: none;
  }
}

.menu {
  &:hover {
    cursor: pointer;
  }

  @include respond-above("laptop") {
    display: none;
  }
}

.user-signout-container {
  display: flex;
}

.user-first-name {
  @include small-text;
  margin-top: 0.5rem;
  font-weight: bold;
  padding-right: 0.25rem;

  @include respond-below("laptop") {
    font-size: 1.7rem!important;
    color: black;    
    margin-left: 0.5rem;
  }
}

.logout-button {
  @include small-text;
  background: none;
  border: 0;
  font-weight: bold;
  font-family: "Lato";
  color: white;
  padding-left: 1rem;
  padding-right: 0.25rem;
  margin-top: 0.5rem;
  cursor: pointer;

  @include respond-below("laptop") {
    color: #153F7A;
    padding-left: 0rem;

  }
}

.button-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: 100%;

  @include respond-below("laptop") {
    flex-direction: column;
    margin-left: 0.5rem;
  }
}

.link-button {
  flex: none;
  padding-left: 1rem;

  @include respond-below("laptop") {
    padding-left: 0rem;
  }
}

.plane-icon {
  transform: rotate(315deg);
  margin-right: -1rem;
}

.app-title-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-below("bigPhone") {
    margin-right: 2rem;
  }
  
  @include respond-between("bigPhone","laptop") {
    margin-right: 4rem;
  }
}