@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.details-modal {
  display: flex;
  flex-direction: column;
  width: 26rem;
  align-items: center;
  position: absolute;
  top: 25%;
  background-color: white;
  padding: 0.8rem 0.5rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;

  // @include respond-between("tablet", "laptop") {
  //   top: 25%;
  //   left: 30%;
  // }

  // @include respond-below("tablet") {
  //   width: 95%;
  //   top: 15%;
  //   left: 0;
  // }
}

.details-modal-field {
  align-items: left;
  display: inline;
  text-align: left;
  white-space: nowrap;
  width: 90%;
}

.details-form-fields {
  display: flex;
  align-items: left;
  width: 100%;
  padding-bottom: 0.35rem;
}

.details-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 85%;
  margin-top: -1rem;
  background-color: #0076c0;
  color: white;
  margin-bottom: 1rem;
  margin: -1rem 0 1rem 1rem;
  justify-content: center;
}

.airplane-rotate {
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  padding-right: 0.25rem;
  transform: rotate(315deg);
}

Modal {
  align-items: center;
}

.details-open:hover {
  cursor: pointer;
}

.details-open {
  border: none;
  color: #344da6;
  text-decoration: underline;
  font-weight: bold;
  background: none;
  text-underline-offset: 0.2rem;
}

.user-information-field {
  display: flex;
  margin-right: 2rem;
}

.reservation-information-field {
  display: flex;
  margin-right: 2rem;
}

.user-icon {
  font-size: 2.5rem;
  padding-right: 1rem;
  color: gray;
}

.ticket-icon {
  font-size: 2.5rem;
  transform: rotate(300deg);
  color: gray;
  margin-top: 2rem;
  padding-right: 1rem;
  margin-left: -0.5rem;
}

label {
  text-align: left;
}

.details-horizontal-line {
  margin: 1rem 0rem;
}

.stamp-icon {
  font-size: 2.5rem;
  padding-right: 1rem;
  color: gray;
}
