@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.reservation-modal {
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: 43rem;
  // align-items: center;
  position: absolute;
  top: 25%;
  background-color: white;
  padding: 1rem 1rem 1rem 4rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;
  align-items: flex-start;
  outline: 0;

  @include respond-between("tablet", "laptop") {
    min-width: 80%;
  }

  @include respond-below("tablet") {
    min-width: 90%;
    padding: 1rem;
  }
}

.reservation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.75);
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  z-index: 9999;
}

.reservation-modal-close {
  display: flex;
  background: none;
  border: none;
  color: #344da6;
  font-size: 1.2rem;
  margin-left: auto;
  padding-bottom: 1rem;
  flex-basis: 2%;
}

.modal-body {
  flex-basis: 98%;
}

.reservation-fields-list {
  width: 65%;
  @include respond-below("tablet") {
    width: 100%;
  }
}

.reservation-modal-close:hover {
  cursor: pointer;
}

.new-reservation-button {
  @include section-title;

  background: none;
  border: none;
  color: #344da6;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.new-admin-reservation-button:hover {
  cursor: pointer;
}

.new-admin-reservation-button {
  @include section-title;

  background: none;
  border: none;
  color: #344da6;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.modify-reservation-button:hover {
  cursor: pointer;
}

.modify-reservation-button {
  border: 2px solid;
  border-color: #707070;
  border-radius: 0.3rem;
  width: 100%;
  margin-bottom: 0.4rem;
  padding: 0.1rem 0rem;
  color: black;

  @include respond-between("phone", "laptop") {
    font-size: 0.7rem;
  }

  @include respond-below("tablet") {
    width: 10rem;
  }
}

.modify-admin-reservation-button:hover {
  cursor: pointer;
}

.modify-admin-reservation-button {
  color: #ad6327;
  border: none;
  background: none;
}

.modify-admin-icon {
  font-size: 1.25rem;
  padding-right: 0.25rem;
}

.new-reservation-button:hover {
  cursor: pointer;
}

.reservation-label {
  @include respond-below("tablet") {
    display: none;
  }
}

.new-icon {
  padding-right: 0.25rem;
}

.reservation-modal-title {
  @include form-title;

  margin-left: 1.5rem;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
}

.reservation-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2.5rem;
}

.reservation-form-label-text {
  @include form-field-name;
  width: 30%;

  padding: 0.3rem;
}

.radio-button-container{
  width: calc(75% + 1.6rem + 6px);
}

.reservation-radio-button {
  width: 50%;
  padding: 0.3rem;
  text-align: center;
}

.open-calendar:hover {
  cursor: pointer;
}

.open-calendar {
  font-size: 1.25rem;
  pointer-events: none;
  background: none;
  border: none;
  color: black;
  font-family: "Lato", sans-serif;
  position: absolute;
  // margin-left: 16rem;
  margin-top: 0.2rem;
  padding-right: 0.5rem;
}

.disabled-open-calendar {
  @extend .open-calendar;
  color: #dcdada;

  @include respond-between("phone", "laptop") {
    padding-right: 0rem;
  }

}

.error-message {
  @include small-text;
  color: #ff0000;
  padding-top: 0.5rem;
}

.datetime-input-button {
  display: flex;
  // position: absolute;
  justify-content: flex-end;
  width: calc(75% + 1.6rem);
}

.datetime-input-button:hover {
  cursor: pointer !important;
}

.reservation-setup-date-pointer {
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  width: 100%;
}

.disabled-datetime-input-button {
  display: flex;
  justify-content: flex-end;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  border: none;
  width: calc(75% + 1.6rem + 8px);
  align-items: center;

  @include respond-below("tablet") {
    color: yellow;
    padding: 1rem;
    width: 75%;
  }
}

form li+li {
  margin-top: 1rem;
}

li {
  white-space: nowrap;
}

label {
  /* Uniform size & alignment */
  display: inline-block;
  width: 7.3rem;
  text-align: right;
}

input {
  text-align: center;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, sans-serif;
}

.reservation-airport-input {
  text-align: left;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  border: 2px solid gray;
  width: 11.75rem;
  transform: rotateY("180");
}

// .airpot-input-div {
//   // width: max-content;
// }

option {
  bottom: 100%;
  text-align: center;
}

// input[type="text"],
// input[type="email"] {
//   border: 2px solid gray;
//   border-color: #707070;
//   background-color: #f0f0f0;
//   border-radius: 0.3rem;
//   padding: 0.4rem 1.2rem 0.4rem 0.4rem;
//   // width: 28rem;
//   width: 75%;
// }
.reservation-modal-input {
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  text-align: left !important;
  width: 75%;
  // -webkit-appearance: none;
}

input[type="text"][name="dateTime"] {
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  text-align: left !important;
  padding-left: 0.4rem;

  @include respond-below("tablet") {
    padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  }
}

input[type="radio"]:hover {
  cursor: pointer;
}

input[type="radio"] {
  margin-left: 0rem;
}

input[type="submit"] {
  @include body-text;

  border-radius: 1.5rem;
  border: none;
  padding: 0.7rem 4rem 0.7rem 4rem;
  color: white;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin-top: 2rem;
  -webkit-appearance: none
}

.submit-button {
  background-color: #010b4b;
}

.submit-button:hover {
  cursor: pointer;
}

.disabled-submit-button {
  background-color: #f0f0f0;
}

ul {
  list-style: none;
  display: inline-block;
}

.reservation-form-fields {
  margin-left: -7.5rem;
  display: flex;
  height: 2rem;
  justify-content: flex-end;

  @include respond-below("tablet") {
    flex-direction: column;
    padding-top: 1rem;
    margin-left: 0rem;
  }
}

h4 {
  display: flex;
  width: 75%;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
}

#date-time-input::placeholder {
  color: black;
  font-style: normal;
}

.rs-dropdown-toggle-caret {
  height: 20px;
  font-size: 12px;
  float: right;
  margin-top: -0.2rem;
  margin-right: 0.4rem;
}

.rs-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  white-space: nowrap;
  // overflow: auto;
}

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  cursor: pointer;
  text-align: left;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  height: 2rem;
  border: 2px solid gray;
  width: 100%;
  padding-top: 0.2rem;
  padding-left: 0.4rem;
  color: black;
}

.rs-dropdown>.rs-dropdown-menu {
  margin-right: -1.3px;
}

.rs-dropdown-menu {
  margin: 0;
  list-style: none;
  font-size: 0.85rem;
  text-align: right;
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 6px 0;
  width: calc(100% - 1.9px);
  border: 2px solid gray;
  margin-right: -2px;
  top: 100%;
  right: 0;
}

.rs-dropdown .rs-dropdown-menu {
  position: absolute;
  z-index: 6;
  float: right;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--rs-dropdown-shadow);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--rs-dropdown-shadow);
}

.rs-dropdown-menu[hidden] {
  display: none;
}

.rs-dropdown-item {
  background-color: #f0f0f0;
  display: block;
  font-weight: normal;
  line-height: 1.42857143;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  margin-top: 0.1rem;
  text-indent: 0.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
}

.rs-dropdown-item.rs-dropdown-item-focus {
  background-color: #E0EBFD;
  color: black;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  padding-top: 0px;
  padding-bottom: 0px;
}

.rs-dropdown-item.open.rs-dropdown-item-submenu:hover>.rs-dropdown-item.rs-dropdown-item {
  width: calc(100%);
}

.rs-dropdown-item.close.rs-dropdown-item-submenu:hover>.rs-dropdown-item.rs-dropdown-item {
  width: 100%;
}

.rs-dropdown-item.close.rs-dropdown-item-submenu {
  width: calc(100%);
}

.rs-dropdown-item-submenu {
  position: relative;
}

.rs-dropdown-item-submenu.rs-dropdown-item>.rs-dropdown-item-toggle .rs-dropdown-menu-toggle-icon {
  position: absolute;
  right: 0%;
  margin: 0;
  font-size: 12px;
  height: 20px;

  @include respond-below("laptop") {
    transform: rotate(90deg);
  }
}

.rs-dropdown-item-submenu .rs-dropdown-menu {
  left: 100%;
  top: 0%;

  @include respond-below("laptop") {
    top: 100%;
    left: 0%;
    margin-left: -0.1rem;
  }
}

.rs-dropdown-item-toggle.rs-dropdown-item {
  overflow: hidden;
}

.close>.rs-dropdown-menu {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.open:hover>.rs-dropdown-menu {
  display: block;
}

.submenu {
  width: 100%;
}

.rs-dropdown-item-submenu>.rs-dropdown-menu {
  width: 100%;
}

.airport-input-div {
  width: calc(75% + 1.6rem + 6px);
  font-size: small;
  // @include form-field-name;
}

.airport-dropdown-0 {
  background-color: #FBFBFB;
}

.airport-dropdown-1 {
  background-color: #ECECEC;
}

.airport-submenu-dropdown-0 {
  background-color: #FBFBFB;
}

.airport-submneu-dropdown-1 {
  background-color: #ECECEC;
}

.rs-dropdown-item-active {
  background-color: #3786D4;
  color: white;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  padding-top: 0px;
  padding-bottom: 0px;
}

.reservation-callsign-input {
  text-transform: uppercase;
}

.aircraft-name-dropdown {
  width: calc(75% + 1.6rem + 8px);
  text-align: center;
  vertical-align: middle;
  // text-indent: 2.5rem;
  justify-content: center;
  align-items: center;
  // -webkit-appearance: none;
}


.caret {
  position: absolute;
  height: 20px;
  font-size: 12px;

}

.aircraft-dropdown-icon {
  position: absolute;
  margin-top: 0.3rem;
  margin-right: 0.4rem;
  pointer-events: none;

  @include respond-below("tablet") {
    left: calc(75% + 8px);
    margin-bottom: .3rem;
  }
}

.circle-question-icon{
  position: absolute;
  margin-left: .3rem;
  margin-top: 0.5%;
  color: #344da6;
  font-size: 1.15rem;

  @include respond-below("tablet") {
    left: calc(80% + 6px);
    bottom: 34%;
    font-size: 1.1rem;
  }
}

.admin-circle-question-icon{
  position: absolute;
  margin-left: .3rem;
  margin-top: 0.5%;
  color: #344da6;
  font-size: 1.15rem;

  @include respond-below("desktop") {
    left: calc(81% + 6px);
    bottom: 20%;
    font-size: 1.1rem;
  }

  @include respond-below("tablet") {
    left: calc(80% + 5px);
    bottom: 16%;
    font-size: 1rem;
  }
}