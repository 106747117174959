@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.new-event-button:hover {
  cursor: pointer;
}
.new-event-button {
  @include section-title;

  background: none;
  border: none;
  color: #344da6;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.new-event-icon {
  padding-right: 0.25rem;
}

.history-table-block {
  height:20rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}
.history-table {
  width: 97%;
  border-style:none;
}
.history-action {
  width: 10%;
  border: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
}
.history-action-empty {
  width: 10%;
  border-color: lightgray;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.history-date {
  width: 15%;
  border-right: 0;
  border: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
}
.history-detail {
  border-color: lightgray;
  white-space: pre-wrap;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding-bottom: 0.5rem;
}