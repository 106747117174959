@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.event-blocks {
  padding: 0 0.5rem;
  margin: 1rem auto 0;
  width: 99%;

  @include respond-between("tablet", "laptop") {
    width: 98%;
  }

  @include respond-below("tablet") {
    width: 95%;
  }
}

.empty-state {
  @include section-title;
  text-align: center;
  color: #555555;
  padding: 0.2rem 0;
  width: 100%;
}

.public-empty-state{
  @include respond-below("tablet") {
    padding-left: 3.5rem;
  }
}

.search-container {
  flex-grow: 2;
  position: relative;
  margin-right: 3rem;
  min-width: 9rem;
  height: 2.5rem;

  @include respond-below("tablet"){
    transform: translateY(3.25rem);
    min-width: 0rem;
    width: 0rem;
    flex-grow: 0;
    margin-right: 0rem;
    left: 40%;
  }
}

.input-search-field {
  @include list-view-small-text;
  position: absolute;
  border-radius: 0.7rem;
  right: 0rem;
  text-align: left;
  min-width: 9rem;
  border: #b0b0b0 solid;
  border: solid #b0b0b0 !important;
  background-color: #ffffff;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  width: 10rem;

  @include respond-below("tablet"){ 
    right: 6rem;
  }

}

.card-list{
  @include respond-above("laptop"){
  width: 85vw;
  }

  @include respond-above("tablet"){
    width: 95vw;
    }
}

.public-button-div{
  display: flex;
  justify-content: flex-end;

  @include respond-below("tablet"){
    justify-content: flex-start;
   }
}

.public-export-button {
  padding-right: 1rem;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  text-decoration: none;
  color: #003e7e;

  @include respond-below("tablet"){
   font-size: 1.1rem;
  }
}

.public-view-search-icon {
  font-size: 1.3rem;
  color: #646464;
  position: absolute;
  top: 20%;
  right: 0.5rem;


  @include respond-below("tablet") {
    right: 6.5rem;
    top: 13%;
  }
}

.public-filter-and-table {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;

  @include respond-below("laptop"){
    padding: 1rem 0.5rem;
    flex-direction: column;
  }
}