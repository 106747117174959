@import "src/helpers/style/media-queries";

.availability-graph {
    overflow-x: auto;
    overflow-y: auto;
    height: fit-content;
    max-width: 95%;
    padding-top: 10%;

    @include respond-below ("desktop"){
        padding-top: 5%;
    }

    @include respond-below("laptop") {
        padding-top: 10%;
        }

    @include respond-below ("tablet"){
        margin-left: -4rem;
        padding-top: 1.5rem;
    }
}

.legend {
    display: flex;
    padding-top: 1rem;
    font-weight: bold;
    align-items: baseline;

    @include respond-below ("tablet"){
        width: 1%;
        display: inline-block;
    }
}

.legend-list {
    // display: inline-block;
    padding-right: 4rem;
}

.legend-labels {
    float: left;
    width: 2rem;
    height: 0.7rem;
    margin: 0.2rem .8rem 0.2rem 0.2rem
}
.available {
    @extend .legend-labels;
    border: 1px solid black;
    background-color: white;
}
.reserved {
    @extend .legend-labels;
    border: 1px solid white; 
    background-color: orange;
}

.full {
    @extend .legend-labels;
    border: 1px solid white ;
    background-color: #bd4e4e;
}

.departures {
    @extend .legend-labels;
    border: 1px solid white ;
    background-color: #AAAAAA;
}

.custom-tooltip{
    border: 1px solid black;
    background: white;
    border-radius: 0.3rem;
    padding: .5rem;
}

.label-available{
    color: black;
}

.label-reserved {
    color: orange;
}

.label-full {
    color: #bd4e4e;
}

.label-departures {
    color: #AAAAAA;
}