.event-window {
  background: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 1px solid;
  //  flex-basis: 45%;
  margin: 0 0.2rem 0 0.2rem;
  padding: 0.1rem;
}

.event-window-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0076c0;
  color: #ffffff;
  border: none;
  font-weight: bold;
  height: 2rem;
}

.event-window-body {
  overflow: auto;
  width: 30.8rem;
  height: 17.6rem;
}

table.event-window-table {
  background-color: white;
}

.event-window-table td {
  text-align: center;
  font-size: 0.8rem;
  height: 1.4rem;
  flex-grow: 1;
  overflow-x: auto;
  // display: flex;
}
.event-window-date-pane {
  flex-grow: 7;
  //  background-color: lightblue;
}

.date-time-label {
  width: 3rem;
}

.event-window-day {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: white;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0.2rem;
  text-align: center;
  outline: 0.1px solid black;
}

.event-window-day:first-child {
  position: sticky;
  left: 0;
  z-index: 6;
}

.event-window-hour {
  position: sticky;
  left: 0;
  z-index: 4;
  background-color: white;
  font-size: 0.8rem !important;
  font-weight: bold;
  padding: 0.2rem;
  text-align: center;
  outline: 0.1px solid black;
}

.defined-time-slot {
  background-color: #caf0c9;
}
