@import "src/helpers/style/media-queries";
@import "src/helpers/text";

@font-face {
  font-family: "Lato";
  src: url("static/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("static/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("static/fonts/Lato/Lato-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

html {
  @include respond-above("widescreen") {
    font-size: 20px;
  }
}

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0;
}

table {
  border-collapse: collapse;

  th,
  td {
    border: 1px solid black;
  }
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

p {
  @include body-text;
}

body.modal-open {
  overflow-y: hidden;
}
