@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.list-view-card {
  flex-basis: 100%;

  @include respond-below("laptop") {
    flex-basis: 100%;
  }
}

.list-view-table {
  border-style: none;
  empty-cells: hide;
  width: 100%;

  @media screen and (min-width: 300px) and (max-width: 1000px) {
    display: block;
    overflow-x: auto;
  }
}

.list-view-table-row:nth-child(odd) {
  background-color: #f5f5f5;
  height: 2.5rem;
}

.list-view-table-row:nth-child(even) {
  background-color: white;
  height: 2.5rem;
}

.generic-table-header {
  background: #0076c0;
  color: #ffffff;
  border: none;
  text-align: left;
  height: 1rem;
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}

.list-view-table-padding-left {
  padding-left: 1rem;
}

.list-view-table-padding-right {
  padding-right: 1rem;
}

.list-view-table-scope-padding {
  @include respond-below("laptop") {
    padding-right: 5rem;
  }
}

.list-view-header-text {
  @include label-text;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
}

.list-view-second-header-element {
  @include label-text;
}

.list-view-table-col-eventname {
  width: 20%;
}

.list-view-table-col-startdate {
  width: 12%;
}

.list-view-table-col-enddate {
  width: 12%;
}

.list-view-table-col-livedate {
  width: 12%;
}

.list-view-table-col-status {
  width: fit-content;
}

.list-view-table-border {
  border: none;
  @include list-view-small-text;
}

.list-view-table-airport-text-generic-table {
  @include list-view-small-text;
  font-weight: normal;
  border: none;
  padding-left: 1rem;
  //  width: 15%;
}

.list-view-table-event-text-high {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #c91010;
  font-weight: normal;
}

.list-view-table-event-text-medium {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #b85e0b;
  font-weight: normal;
}

.list-view-table-event-text-low {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: normal;
}

.list-view-table-advisory-link {
  transform: scale(0.8);
}

.list-view-table-delay-trend-link {
  transform: scale(0.8);
}

.list-view-table-scope-link {
  transform: scale(0.8);
}

.list-view-link-align {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 9000;
}

.list-view-table-fixed {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    left: 0;
    position: sticky;
    z-index: 9998;
    padding-right: 0.3rem;
  }
}

.list-view-table-fixed-second {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    position: sticky;
    z-index: 9998;
    left: 4.3rem;
  }

  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.85rem;
    padding-left: 1rem;
  }
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b0b0b0;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b0b0b0;
  font-style: italic;
}

// table tr:nth-child(odd) td:nth-child(1) {
//   @media screen and (min-width: 300px) and (max-width: 990px) {
//     background-color: #f5f5f5;
//     left: 0;
//     position: sticky;
//     z-index: 9998;
//   }
// }

// table tr:nth-child(odd) td:nth-child(3) {
//   @media screen and (min-width: 300px) and (max-width: 990px) {
//     background-color: #f5f5f5;
//     left: 4.4rem;
//     position: sticky;
//     z-index: 9998;
//   }

//   @media screen and (min-width: 300px) and (max-width: 650px) {
//     left: 3.8rem;
//   }
// }

// table tr:nth-child(even) td:nth-child(1) {
//   @media screen and (min-width: 300px) and (max-width: 990px) {
//     background-color: white;
//     left: 0;
//     position: sticky;
//     z-index: 9998;
//   }
// }

// table tr:nth-child(even) td:nth-child(3) {
//   @media screen and (min-width: 300px) and (max-width: 990px) {
//     background-color: white;
//     left: 4.4rem;
//     position: sticky;
//     z-index: 9998;
//   }

//   @media screen and (min-width: 300px) and (max-width: 650px) {
//     left: 3.8rem;
//   }
// }

// .filter-and-table {
//   display: flex;
//   align-items: flex-start;
// }

.active-status-icon {
  @include very-small-text;
  color: #54b357;
  padding-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.disabled-status-icon {
  @include very-small-text;
  color: red;
  padding-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.inprogress-status-icon {
  @include very-small-text;
  color: #c5a817;
  padding-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.expired-status-icon {
  @include very-small-text;
  color: #696a69;
  padding-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.icon-play-circle {
  padding-right: 0.3rem;
}

.planner-activate-button {
  background: none;
  border: none;
  font-weight: bold;
  color: dimgray;
  padding-left: 0.5rem;
}

.planner-activate-button:hover {
  cursor: pointer;
}

.last-one {
  text-align: right;
  padding-right: 2rem;
  width: fit-content;
}

.planner-modify-button:hover {
  cursor: pointer;
}
.planner-modify-button {
  color: #ad6327;
  font-weight: bold;
  border: none;
  background: none;
  padding-left: 0.5rem;
}

.planner-modify-icon {
  font-size: 0.8rem;
  padding-right: 0.25rem;
}
