@import "src/helpers/style/media-queries";

.dot-bar {
  background: #15396c;
  height: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  a:hover {
    color: #b5b5b5;
  }

  > * + * {
    padding-left: 3rem;
  }

  @include respond-below("tablet") {
    justify-content: normal;
    flex-direction: column;
    align-items: flex-start;
    height: 3rem;
    font-size: 0.5rem;
  }
}

.dot-logo-header {
  height: 1rem;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  width: 1rem;
  padding-left: 3rem;

  @include respond-between("phone", "tablet") {
  }

  @include respond-below("tablet") {
    padding-left: 1.2rem;
    font-size: 2rem;
  }
}

.dot-logo-header-container {
  display: flex;
  align-items: center;
}

.dot-about-links {
  display: flex;
  justify-self: flex-end;

  @include respond-below("tablet") {
    padding-left: 1.2rem;
    font-size: 0.8rem;
  }
}

.dot-header-link {
  text-decoration: none;
  font-size: 0.87rem;
  color: inherit;
  padding: 0.2rem 0.7rem;

  @include respond-between("tablet", "laptop") {
    font-size: 0.8rem;
  }

  @include respond-below("tablet") {
    font-size: 0.7rem;
  }
}
