@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.list-view-card {
  flex-basis: 100%;
  @include respond-below("laptop") {
    flex-basis: 100%;
    overflow-x: auto;
    margin-top: 0.25rem;
  }
}
.list-view-table {
  border-style: none;
  empty-cells: hide;
  width: 100%;

  @media screen and (min-width: 300px) and (max-width: 1000px) {
    display: block;
    overflow-x: auto;
  }
}

.list-view-table-row:nth-child(odd) {
  background-color: #f5f5f5;
  height: 2.5rem;
}

.list-view-table-row:nth-child(even) {
  background-color: white;
  height: 2.5rem;
}

.list-view-table-header {
  background: #0076c0;
  color: #ffffff;
  border: none;
  text-align: left;
  // padding-bottom: 1rem;
}

.list-view-table-padding-left {
  padding-left: 1rem;
}

.list-view-table-padding-right {
  padding-right: 1rem;
}

.list-view-table-scope-padding {
  @include respond-below("laptop") {
    padding-right: 5rem;
  }
}

.list-view-header-text {
  @include label-text;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
}

.list-view-second-header-element {
  @include label-text;
}

.list-view-table-border {
  border: none;
  @include list-view-small-text;
}

.list-view-table-airport-text {
  @include list-view-small-text;
  font-weight: normal;
  border: none;
  padding-left: 1rem;
}

.list-view-table-event-text-high {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #c91010;
  font-weight: normal;
}

.list-view-table-event-text-medium {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #b85e0b;
  font-weight: normal;
}

.list-view-table-event-text-low {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: normal;
}

.list-view-table-advisory-link {
  transform: scale(0.8);
}

.list-view-table-delay-trend-link {
  transform: scale(0.8);
}

.list-view-table-scope-link {
  transform: scale(0.8);
}

.list-view-link-align {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 9000;
}

.list-view-table-fixed {
  @media screen and (min-width: 300px) and (max-width: 1030px) {
    left: 0;
    position: sticky;
    z-index: 9998;
    padding-right: 0.3rem;
  }
}
.list-view-table-fixed-second {
  @media screen and (min-width: 300px) and (max-width: 1030px) {
    position: sticky;
    z-index: 9998;
    left: 4.3rem;
    border-right:solid white!important;
  }

  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.85rem;
    padding-left: 1rem;
    border-right:solid white!important;
  }
}

.list-view-header-search {
  position: relative;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  width: 80%;
  min-width: 9rem;
  height: 2.5rem;
}

.list-view-table-search-field {
  @include list-view-small-text;
  position: absolute;
  border-radius: 0.7rem;
  border: 0rem;
  top: 0.1rem;
  right: 0rem;
  text-align: left;
  width: 105% !important;
  border-color: #707070;
  background-color: #f0f0f0;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
}

.search-icon {
  font-size: 1.3rem;
  color: #646464;
  position: absolute;
  top: 20%;
  right: 0.5rem;
}
::placeholder {
  color: #b0b0b0;
  font-style: italic;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b0b0b0;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b0b0b0;
  font-style: italic;
}

.filter-container {
  display: flex;
  flex-flow: column nowrap;
  background: white;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex-basis: 15%;
  margin-top: 0.9rem;
  color: dimgrey;
  margin-bottom: 10%;

  @include respond-below("laptop"){
    display: none;
  }

}

.filter-icon-clicked{
  display: flex;
  flex-flow: column nowrap;
  background: white;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  flex-basis: 15%;
  margin-top: 0rem;
  color: dimgrey;

  @include respond-below("laptop"){
    position: absolute;
    z-index: 9999;
    width: 40%;
    }
}

.filter-icon{
  color: #344da6;
}

.filter-icon-label{
  display: flex;
  flex-direction: row;
  margin-top: -0.25rem;
  color: #344da6;
  font-weight: bold;
  font-size: 1rem;
}

.filter-icon-label{
  font-size: 1.3rem;
  margin-top: -0.2rem;

  @include respond-above("laptop")
  {
    display: none;
  }
}

.filter-close{
      position: absolute;
      right: 5%;
      background: none;
      border: none;
      color: #344da6;
      font-size: 1.2rem;

      @include respond-above("laptop")
      {
        display: none;
      }
    }

.filter-header {
  text-align: center;
  padding-bottom: 1rem;
  
  @include respond-below("laptop"){
    font-size: 1.15rem;
  }
}

.filter-subheader {
  @include form-title;
  padding-bottom: 0.25rem;
  padding-left: 0.3rem;
}

.filter-label {
  padding-left: 0.3rem;
  padding-bottom: 0.75rem;
  width: fit-content;
}

.filter-and-table {
  display: flex;
  align-items: flex-start;

  @include respond-below("laptop"){
    // flex-direction: column;
    background: white;
    padding: 1rem 0.5rem;
    flex-direction: column;
  }
}

input[type="checkbox"] {
  accent-color: gray;
}

input[type="checkbox"]:hover {
  cursor: pointer;
  }
  
.table-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.airport-filter-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 0.3rem;
  width: 97%;
}
.airport-filter {
  flex: 0 0 24%;
  margin-bottom: 0.2rem;
}

table tr:nth-child(odd) td:nth-child(1) {
  @media screen and (min-width: 300px) and (max-width: 1030px) {
    background-color: #f5f5f5;
    left: 0;
    position: sticky;
    z-index: 9998;
  }
}

table tr:nth-child(odd) td:nth-child(2) {
  @media screen and (min-width: 300px) and (max-width: 1030px) {
    background-color: #f5f5f5;
    left: 4.4rem;
    position: sticky;
    z-index: 9998;
    border-right:solid white;

  }
  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.8rem;
    border-right:solid white;
  }
}

table tr:nth-child(even) td:nth-child(1) {
  @media screen and (min-width: 300px) and (max-width: 1030px) {
    background-color: white;
    left: 0;
    position: sticky;
    z-index: 9998;
  }
}

table tr:nth-child(even) td:nth-child(2) {
  @media screen and (min-width: 300px) and (max-width: 1030px) {
    background-color: white;
    left: 4.4rem;
    position: sticky;
    z-index: 9998;
    border-right:solid white;
  }

  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.8rem;
    border-right:solid white;
  }
}