@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.confirmation-dialog {
  display: flex;
  flex-direction: column;
  width: 32rem;
  min-height: 8rem;
  align-items: center;
  position: absolute;
  top: 35%;
  background-color: white;
  padding: 0.5rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;
}

.confirmation-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.75);
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.dialog-header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
}
.dialog-header-underline {
  @extend .dialog-header;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid black; ;
}
.dialog-title {
  font-weight: bold;
}
.dialog-xout {
  background: none;
  border: none;
  color: #344da6;
  font-size: 1.2rem;
  padding-right: 0.5rem;
}

.dialog-description-band{
  flex-grow: 2;  
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  margin-left: 1rem;
}
.dialog-short-message {
  margin-left: 20%;
}

.dialog-question-band{
  flex-grow: 1;  
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
}

.dialog-button-band{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;  
  align-items: center;
  width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}

.dialog-no:hover {
  cursor: pointer;
}
.dialog-no {
  @include small-text;
  background-color: #6e737c;
  color: white;
  font-weight: bold;
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.dialog-yes{
  @extend .dialog-no;
  background-color: #B85E0B;
  width: 12rem;
}

.dialog-cancel{
  @extend .dialog-no;
  background-color: lightgray;
}

.success-color {
  color: #36AF56;
}
.warning-color {
  color: #B85E0B;
}
.error-color {
  color: #CB444A;
}
.body-text-large {
  font-size: 1.5rem;
  font-weight: bold;
}

.content-noentry-icon{
  width: 2rem;
  height: 2rem;;
}
.content-lead-icon {
  font-size: 2rem;
}
.content-lead-spacer {
  width: 1rem;
}

.comment-band {
  min-width: 30rem;
  max-width: 30rem;
  min-height: 6rem;
}
.comment-label {
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}
.comment-text {
  @include small-text;
  min-width: 100%;
  max-width: 100%;
  min-height: 5rem;
  font-family: "Lato", sans-serif;
  margin-bottom: 1rem;
}