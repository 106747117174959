@import "src/helpers/text";
@import "src/helpers/style/media-queries";


.reservation-card-container {
  padding: 1rem 2.5%;
  display: flex;

  @include respond-between("phone","desktop"){
    padding: 1rem 0.625% 1rem 2.5%;
  }

  @include respond-below("tablet"){
    flex-wrap: wrap;
  }
}

.reservation-card-info {
  width: 12.5%;

  @include respond-below("tablet"){
    padding-bottom: 0.5rem;
  }
}

.reservation-card-last-info {
  width: 20%;

  @include respond-below("tablet"){
    width: 31.4%;
    margin-left: 1.5rem;
    }  
}

.reservation-card-buttons {
  width: 10%;

  @include respond-between("phone","desktop"){
    margin-left: 1.875%;
  }

  @include respond-between("phone","laptop"){
    margin-top: -0.5rem;
  }
  @include respond-below("tablet"){
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 0.25rem;
  }
}

.cancel-reservation-button:hover {
  cursor: pointer;
}
.cancel-reservation-button {
  border: 2px solid;
  border-color: #707070;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.1rem 0rem;
  color: black;

  @include respond-between("phone","laptop"){
    font-size: 0.7rem;
  }
  @include respond-below("tablet"){
    width: 10rem;
  }
}

h3 {
    font-size: 1.35rem;
  
    @include respond-between("laptop", "desktop") {
      font-size: 1.2rem;
    }

    @include respond-between("tablet", "laptop") {
      font-size: 0.85rem;
    }
  
    @include respond-below("tablet") {
      font-size: 0.7rem;
    }
  }

  .aircraft-type{
    @include respond-below("tablet"){
      display: none;
    }
    @include respond-between("phone","laptop"){
      display: none;
    }
  }

  .aircraft-type-tablet{
    font-size: 0.7rem;

    @include respond-below("tablet"){
      display: none;
    }
    @include respond-above("laptop"){
      display: none;
    }    
  }
  .aircraft-type-mobile{
    @include respond-above("tablet"){
      display: none;
    }
  }

  .mobile-card-label{
    @include respond-between("tablet", "laptop") {
      font-size: 0.7rem;
    }
    @include respond-below("tablet"){
      font-size: .6rem;
    }
  }

  .small-card-info-mobile{
    @include respond-below("tablet"){
      width: 15%;
    }
  }

  .medium-card-info-mobile{
    @include respond-below("tablet"){
      width: 27.6%;
    }
  }

  .event-mobile{
    @include respond-below("tablet"){
      width: 41.6%;
      margin-left: 7%;
    }
  }

  .time-mobile{
    @include respond-between("phone","laptop"){
      margin-left: 2%;
    }
    @include respond-below("tablet"){
      margin-left: -6%;
    }
  }
  
  .type-mobile{
    @include respond-below("tablet"){
      margin-left: 1rem;    
  }
}

  .card-eol-mobile{
    @include respond-below("tablet"){
      width: auto;
      margin-left: auto;
    }
  }