@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.group-modal{
    width: 33%;
    padding: 1.6rem 1rem;
}

.group-warning-message{
    padding: 0;
}

.warning-icon {
    width: 2.5rem;
    height: min-content;
    color: #bd4e4e;
  }

.group-new-access-text{
    padding-left: 0.8rem;
}

.group-email-notification{
    @include body-text;
    padding: 3rem 0;
}

.group-question{
    @include body-text;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: bold;
}    