@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.calendar-time-container {
  margin-top: 1rem;
  margin-left: 0.5rem;

  @include respond-below("tablet"){
    display: flex;
    flex-direction: column;  
    align-items: center;
  }
}

.select-calendar:hover {
  cursor: pointer;
}
.select-calendar {
  background-color: #010b4b;
  padding: 0.5rem 2rem;
  border: none;
  color: white;
  border-radius: 1.5rem;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  float: right;
  margin-top: 1rem;
}

.calendertime {
  display: flex;
  justify-content: space-around;
  padding-top: 2.5rem;
  height: 21rem;

  @include respond-below("tablet"){
    flex-direction: column;
    height: fit-content;
  }
}

.calendar-header {
  display: flex;
  align-items: center;
}

.left-line {
  border-left: 1px solid gray;
  height: 22rem;
  margin-left: 2rem;
  left: 50%;
  margin-top: -1rem;

  @include respond-below("tablet"){
    display: none;
  }
}

.calendar-time-header {
  @include form-title;
  color: black;
}

.selected-day {
  @include form-title;
  margin-left: auto;
  color: black;

  @include respond-below("tablet"){
    display: none;
  }
}

.react-calendar {
  width: 20rem;
}

.react-calendar__tile {
  background-color: white;
  border-radius: 70%;
  height: 3rem;
  width: 100%;
  font-weight: bold;
}

.react-calendar {
  width: 20rem;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-top: -0.6rem;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  @include subtitle;
  background-color: white;
  color: black;
  font-weight: bold;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  text-decoration-line: none !important;
  border: none !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  color: black;
}
.react-calendar__tile:disabled {
  background-color: white !important;
  font-weight: normal;
  color: #B7B7B7!important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: white;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
