@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.availability-modal {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 80%;
  align-items: center;
  position: absolute;
  top: 10%;
  background-color: white;
  padding: 0.8rem 0.5rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;

  @include respond-between("tablet", "laptop") {
  height: 60%;
  }

  @include respond-below("tablet") {
    top: 10%;
    height: 85%;
    width: 96%;
  }
}

.availability-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.75);
  display: flex;
  justify-content: center;
}

.availability-modal-close {
  background: none;
  border: none;
  align-self: flex-end;
  color: #344da6;
  font-size: 1.2rem;
  padding-right: 0.5rem;
}

.availability-modal-close:hover {
  cursor: pointer;
}

.availability-button {
  @include body-text;

  background: none;
  border: none;
  color: #344da6;
  font-family: "Lato", sans-serif;
}

.graph-logo {
  padding-right: 0.3rem;
}

.availability-fields-container {
  // margin-left: -11.5rem;
  display: flex;
  padding-top: 3rem;
}

.availability-fields {
  // margin-left: -11.5rem;
  display: flex;
  // padding-top: 3rem;
  @include respond-below("tablet"){
    flex-direction: column;
  }

}

.availability-label-text {
  @include form-field-name;
  width: 37.5%;

  padding: 0.4rem 0.6rem 0 0;
}

.availability-fields:nth-child(2) {
  padding-left: 6rem;
  @include respond-below("tablet"){
    padding-left: 2rem;
  }
}

.availability-dropdown {
  text-align-last: left;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  border: 2px solid gray;
  width: 20rem;
  outline: none;
  color: black;

  @include respond-below("tablet"){
    width: 100%;
  }
}


.availability-request-type{
  text-align-last: left;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  border: 2px solid gray;
  width: 11.75rem;
  outline: none;
  color: black;

  @include respond-below("tablet"){
    width: 100%;
  }
}