@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.available-times-container {
  display: flex;
  width: 15rem;
  flex-direction: column;
  padding-left: 2rem;
}

.available-time-button:hover {
  cursor: pointer;
}
.available-time-button {
  width: 30%;
  margin-right: 3%;
  margin-top: 1rem;
  background: white;
  border-style: solid;
  border-radius: 0.25rem;
  color: black;
  border-color: #767676;
}

.button-color:hover {
  cursor: pointer;
}
.button-color {
  width: 30%;
  margin-right: 3%;
  margin-top: 1rem;
  background: white;
  border-style: solid;
  border-radius: 0.25rem;
  color: white;
  background-color: #006edc;
}

.available-times {
  @include body-text;
  color: black;
}

.available-times-list {
  margin-top: 1rem;
}

.no-times-available {
  margin-top: 0.9rem;
}

.reserved-reservations {
  font-size: 0.6rem;
  border-top: solid;
  background-color: white;
  border-radius: 0rem 0rem 0.125rem 0.125rem;
  color: black;
  pointer-events: none;
}

.reserved-reservations-warning {
  font-size: 0.6rem;
  background-color: #cb444a;
  color: white;
  border-top: solid;
  border-color: black;
  border-radius: 0rem 0rem 0.125rem 0.125rem;
  pointer-events: none;
}

.available-times-list {
  overflow-y: scroll;
}

#scroll::-webkit-scrollbar {
  width: 0.5rem;
}

#scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
