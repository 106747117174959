@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.force-reservation-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #fff;
  border-radius: 0.4rem;
  border: 1px solid #999;
  padding: 1rem;
  margin-top: -20rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  margin-right: 3rem;
  align-items: center;
}

.force-reservation-warning {
  color: #c72e2e;
}

.force-reservation-warning-icon {
  width: 2.5rem;
  height: auto;
  color: #bd4e4e;
}

.force-reservation-div {
  display: flex;
}
.force-reservation-modal-buttons {
  display: flex;
  flex-wrap: nowrap;
}
.force-reservation-modal-confirm:hover {
  cursor: pointer;
}
.force-reservation-modal-confirm {
  @include small-text;
  background-color: #cb444a;
  color: white;
  font-weight: bold;
  width: 11rem;
  height: 2rem;
  border: none;
  border-radius: 0.25rem;
  margin-left: 1rem;
}

.force-reservation-modal-close:hover {
  cursor: pointer;
}
.force-reservation-modal-close {
  @include small-text;
  background-color: #6e737c;
  color: white;
  font-weight: bold;
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 0.25rem;
  margin-right: 1rem;
}

.force-reservation-question {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: medium;
}
