@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.aiport-spec-header {
  display: flex;
  text-align: center;
}

.airport-rules-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-left: 2.5rem;
}

.airport-rules-modal-title {
  font-size: 1.2rem;
  // margin-left: 1.5rem;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
}

.airport-form-fields {
  // margin-left: -7.5rem;
  display: flex;
  height: 2rem;
}

.airport-form-fields-boxed {
  // margin-left: -7.5rem;
  display: flex;
  height: 2rem;
}

.airport-form-label-text {
  margin-left: -9rem;
  text-align: right;
  width: 9rem;
  line-height: 1.8rem;
  vertical-align: middle;
}

.airport-radio-button:hover {
  cursor: pointer;
}
.airport-radio-button {
  margin-left: 1rem;
  width: 4.37rem;
  padding: 0.3rem;
}

.reservation-limits-input-container {
  border: solid;
  border-color: #707070;
  background: #FBFBFB;
  width: 40rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
}

.reservation-limits-label {
  @include very-small-text;
  margin-right: -3rem;
  align-self: center;
  width: 10rem;
  text-align: right;
}

.airport-rules-items-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interval-container {
  position: absolute;
  // top: 25%;
  left: 30%;
  right: 40%;
  background-color: white;
  padding: 1rem;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.4rem;
  overflow-y: scroll;
  width: 30rem;
  z-index: 9999;
  border-style: solid;
  border-width: thick;
  border-color: #010b4b;
}

.airport-spec-modal {
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 50rem;
  position: absolute;
  top: 0%;
  background-color: white;
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 0.4rem;
  align-items: center;
}

.airport-spec-limit {
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: 60rem;
  align-items: flex-start;
}

.close-button {
  min-width: 4rem;
}

.calendar-container-airport-rules {
  position: absolute;
  // top: 25%;
  left: 40%;
  right: 40%;
  background-color: white;
  padding: 1rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;
  overflow-y: scroll;
  width: 22rem;
  z-index: 9999;
  border-style: solid;
  border-width: thick;
  border-color: #010b4b;
}

.list-in-box {
  width: 100%;
}

.open-calendar-airport:hover{
  cursor: pointer !important;
}
.open-calendar-airport {
  font-size: 1.25rem;
  pointer-events: none;
  cursor: pointer !important;
  background: none;
  border: none;
  color: black;
  font-family: "Lato", sans-serif;
  position: absolute;
  margin-left: 16rem;
  margin-top: 0.2rem;
  padding-right: 0.5rem;
}

.calendar-airport-disabled {
  @extend .open-calendar-airport;
  color: #dcdada;
}


.airport-form-first {
  margin-top: 2rem;
}

.program-setup-times-pointer {
  width: 14rem;
  text-align: left;
  overflow-wrap: break-word;
  border: 2px solid #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  resize: none;
  overflow-y: hidden;
  margin-left: 2rem;
  cursor: pointer;
}

.program-setup-times-disabled {
  @extend .program-setup-times-pointer;
  border: 2px solid #dcdada;
  background-color: #f0f0f0;
  cursor: default;
}

.airport-spec-date-pointer:hover {
  cursor: pointer;
}

.airport-spec-date-pointer {
  width: 14rem;
  margin-left: 2rem;
  border: 2px solid gray;
  border-color: #707070;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
}

.airport-spec-date-pointer-grayed-out {
  margin-left: 2rem;
  border-color: #f0f0f0!important;
  color: #f0f0f0;
  background-color: #f0f0f0;
  border: 2px solid gray;
  border-radius: 0.3rem;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
}

.table-input-text {
  width: 3.1rem !important;
  background-color: white !important;
  border-radius: 0rem !important;
  border: 0px solid gray !important;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
}

.reservation-per-quarter-list {
  height: 4.5rem;
  margin-top: 2rem;
}

.reservation-per-quarter-table {
  margin-left: 2rem;
}

.quarterly-bucket-table-header {
  background-color: #0076c0;
  color: white;
  text-align: center;
  width: 16rem;
}

.quarterly-bucket {
  background-color: #f0f0f0;
  text-align: center;
}

.airport-form-input {
  width: 14rem;
  text-align: center;
}

.airport-name-fixed {
  @extend .airport-form-input;
  font-weight: bold;
  vertical-align: middle;
  line-height: 1.8rem;
}

.airport-form-input-narrow {
  @extend .airport-form-input;
  width: 10rem;
  margin-left: 1rem;
}

.airport-name-dropdown {
  margin-top: -0.4rem;
  width: 25rem;
  text-align: left;
  vertical-align: middle;
}

.request-type-container {
  width: 15.6rem;
  margin-left: 2rem;
}
hr.horizontal-spacer {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0.15rem #a0a0a0 solid;
  width: 90%;
}

.depart-arive-windows {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  //  border: 1px black solid;
}

.days-of-week-repeat{
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-color: #8A8A8A;
  border-width: thick;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.occurrence-container{
  display: flex;
  align-items: center;
  width: 75%;
  margin-left: 6rem;
}

.repeat-button-container{
  display: flex;
  flex-direction: column;
  margin-left: -7rem;
  margin-right: -2rem;
}

.repeat-label:hover {
  cursor: pointer;
  }

  .grayed-out{
    color: #dcdada!important;
  }

.days-of-week-repeat-top{
  display: flex;
  
  margin-bottom: 8px;
}

.days-of-week-repeat-top-days{
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.days-of-week-repeat-top-daily {
  border-right: 2px solid gray;
  width: 3.3rem;
  padding-right: 5px;
  margin-right: 5px;
}

.days-of-week-repeat-bottom{
  flex-direction: row;
  display: flex;
//  flex-wrap: wrap;
  border-top: 2px solid gray;
  padding-top: 5px;
  justify-content: flex-end;
}

.weekday-labels{
  display: inline-block;
  width: 5rem;
}

.repeat-range-label {
  width: 6.5rem;
}
.repeat-range-label-small {
  width: 4rem;
}