@import "src/helpers/style/mixins";
@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.section-header {
  @include section-title;

  margin: 0 $cardHorizontalMargin 1rem;
  padding: 1rem 0 0rem 0;
  border-bottom: 0.5px solid #000000;
  font-weight: bold;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
