@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.home {
  display: flex;
  margin: 0 auto;
  flex-flow: column wrap;
}

.flex-wrapper {
  // display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between;
}

.event-card {
  display: flex;
  flex-flow: column nowrap;

  background: white;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  margin: 0.5rem $cardHorizontalMargin;
  flex-basis: 30%;

  margin-top: 0.9rem;

  padding: 0.5rem;

  @include respond-between("tablet", "laptop") {
    flex-basis: 46.5%;
  }

  @include respond-below("tablet") {
    flex-basis: 100%;
    margin: 0.5rem $cardHorizontalMargin;
    padding: 1rem 2.5%;
  }
}

.card-title {
  font-weight: bold;
  font-size: 1.3rem;
}

.card-content-block {
  border-top: 1px solid #cfcfcf;
  margin-top: 0.50rem;
  padding-top: 0.75rem;
}

.event-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: -2.5rem;
  //  padding: 1rem 1.5%;

  @include respond-between("tablet", "laptop") {
    flex-basis: 46.5%;
  }

  @include respond-below("tablet") {
    flex-basis: 100%;
    margin: -2.5rem $cardHorizontalMargin;
    padding: 1rem 2.5%;
  }
}

.reservations-button {
  @include small-text;
  background: none;
  border: 0;
  font-weight: bold;
  font-family: "Lato";
  color: #2a358a;
  padding-left: 1rem;
  padding-right: 0.25rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.link-button {
  @include small-text;
  flex: none;
  padding-left: 1rem;
  color: #2a358a;

  @include respond-below("laptop") {
    padding-left: 0rem;
  }
}

.plane-icon {
  transform: rotate(315deg);
  margin-right: -0.75rem;
}

.event-card-header {
  display: flex;
  justify-content: space-between;
}

.event-card-time {
  font-style: italic;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.card-description {
  font-family: 'Lato';
  font-size: 1.1rem;
  min-height: 5rem;
  padding-bottom: 1rem;
}

.card-links-container {
  border-top: 1px solid #cfcfcf;
  padding-top: 0.65rem;
  margin-top: 1.1rem;
  display: flex;
  margin-top: auto;
}

.domestic-notice-link {
  padding-right: 2rem;
  color: #344da6;
  ;
  text-decoration: none;
}

.domestic-notice-icon {
  padding-right: .5rem;
}

.reservation-time {
  font-weight: bold;
  padding-bottom: .5rem;
}