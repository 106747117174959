@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.card {
  display: flex;
  flex-flow: column nowrap;

  background: white;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  margin: 0.5rem $cardHorizontalMargin;
  flex-basis: 29.8%;

  margin-top: 0.9rem;


  @include respond-below("laptop") {
    // margin-left: -1rem;
  }

  @include respond-between("tablet", "laptop") {
    flex-basis: 46.5%;
  }

  @include respond-below("tablet") {
    flex-basis: 100%;
    margin: 1rem $cardHorizontalMargin;
    // margin-left: -1rem;
  }
}

// .card-external-link {
//   @include body-text;
//   font-size: 1.125rem;
//   color: #344da6;
//   text-decoration: none;
// }

// .card-external-link:hover {
//   text-decoration: underline;
// }

.status-banner {
  background: #0076c0;
  height: 1.5rem;
  border-radius: 5px 5px 0px 0px;

  @include respond-below("desktop") {
    height: 1rem;
  }

  @include respond-below("tablet") {
    height: 1rem;
  }
}
