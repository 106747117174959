@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.hda-button-active {
  @include form-title;
  outline: none;
  border: 0px;
  background: none;
  font-weight: bold;
  margin: 0 4rem 0 4rem;
  color: #2a4297;
  text-decoration: underline;
}

.hda-button-active:hover {
  cursor: pointer;
}

.hda-button {
  @include form-title;
  outline: none;
  border: 0px;
  background: none;
  font-weight: bold;
  margin: 0 4rem 0 4rem;
  color: black;
}

.hda-button:hover {
  cursor: pointer;
}

.filter-subheader {
  padding-bottom: 0.25rem;
  padding-left: 0.3rem;
}

.filter-label {
  padding-left: 0.3rem;
}

.scroll-container {
  width: 70%;
  padding: 0rem 0.5rem 0.1rem 0.5rem;
  background: white;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.125);
  border-radius: 0.4rem;
  margin-top: -1rem;
  margin-bottom: 0.75rem;
  margin-left: 2rem;

  @media screen and (min-width: 300px) and (max-width: 1220px) {
    width: 64%;
  }
  @include respond-below("laptop"){
    width: 47%;
    margin-left: 0rem;
  }
  @include respond-below("tablet"){
    width: 40%;
  }

  @include respond-below("bigPhone"){
    width: 30%;
  }
}

.hda-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  display: flex;
  justify-content: center;
  @include respond-below("laptop"){
    justify-content: flex-start;
  }
}

.hda-container-with-events {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;

  > *:first-child
  {
    margin-left: 0rem;
  }
  > *:last-child {
    margin-right: 0rem;
  }
}

#HDA-container-scroll::-webkit-scrollbar {
  height: 0.25rem;
}

#HDA-container-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #B1AFAF;
}
