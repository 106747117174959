@import "src/helpers/style/mixins";
@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.faa-footer {
  min-height: $headerFooterHeight;
  align-items: center;
  margin-top: 2rem;
  border-top: 0.2rem solid #937206;

  color: #ffffff;
  background: #003e7e;
  display: flex;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  @include respond-below("tablet") {
    justify-content: left;
    padding: 2rem 2rem;
  }
}

.faa-footer-column-container {
  min-width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-around;

  @include respond-below("tablet") {
    flex-direction: column;
    min-width: inherit;
    margin: 0 0;
  }
}

.dot-footer {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  min-height: $headerFooterHeight;
  align-items: center;
  padding: 1.25rem 2rem;

  @include respond-below("tablet") {
    flex-direction: column;
    min-width: inherit;
    align-items: start;
    padding: 0 0 1rem 2rem;
  }
}

.dot-address {
  font-style: normal;
  text-decoration: none;
  font-weight: bold;
}

.dot-logo-container {
  display: flex;
  align-items: center;

  ~ .address {
    margin-right: 0.25rem;
  }
  @include respond-below("tablet") {
    ~ .footer-column {
      padding-top: 1rem;
    }
  }
}

.dot-link {
  @include small-text;
  display: flex;
  text-decoration: none;
  color: #344da6;
  font-weight: bold;

  ~ .dot-link {
    margin-top: 0.25rem;
  }

  ~ p {
    margin: 0;
    margin-top: 0.25rem;
    @include small-text;
    font-weight: normal;
  }
}

.dot-link:hover {
  text-decoration: underline;
  color: #003e7e;
}

.footer-column {
  display: flex;
  flex-direction: column;

  @include respond-below("tablet") {
    padding: 0;
    ~ .footer-column {
      padding-top: 1rem;
    }
  }
}

.footer-column-header {
  @include footer-header;

  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0;

  @include respond-between("tablet", "desktop") {
    font-size: 1rem;
  }
}

.dot-logo {
  max-width: 5.1875rem;
  min-width: 3.75rem;
  width: 100%;
  padding: 1rem 1rem;

  @include respond-below("tablet") {
    padding-left: 0;
  }
}

.version-style {
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 0.5rem;
}

.contact-footer {
  margin-top: 0.25rem;
}
