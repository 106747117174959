@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.select-interval-submit {
  background-color: #010b4b;
  padding: 0.5rem 2rem;
  border: none;
  color: white;
  border-radius: 1.5rem;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  float: right;
  margin-top: 1rem;
  width: 27%;
  margin-left: 9rem;
}

.available-intervals-container {
  display: flex;
  // width: 18rem;
  flex-direction: column;
  padding-left: 2rem;
}

.available-interval-button {
  width: 22%;
  margin-right: 3%;
  margin-top: 1rem;
  background: white;
  border-style: solid;
  border-radius: 0.25rem;
  color: black;
}

.unavailable-interval-button {
  @extend .available-interval-button;
  color: lightgray;
}

.button-color-interval:hover {
  cursor: pointer;
}

.button-color-interval {
  width: 22%;
  margin-right: 3%;
  margin-top: 1rem;
  background: white;
  border-style: solid;
  border-radius: 0.25rem;
  color: white;
  background-color: #006edc;
}

.interval-header {
  text-align: center;
}

.interval-subheader {
  text-align: center;
}
