@import "src/helpers/style/media-queries";
@import "src/helpers/style/mixins";
@import "src/helpers/text";

.list-view-card {
  flex-basis: 100%;

  @include respond-below("laptop") {
    flex-basis: 100%;
    // margin-left: -3.25rem;
    width: 100%;
  }
}

.list-view-table {
  border-style: none;
  empty-cells: hide;
  width: 100%;

  @media screen and (min-width: 300px) and (max-width: 1000px) {
    display: block;
    overflow-x: auto;
  }

}

.report-table{

  @include respond-below("tablet") {
    margin-top: 3rem;
  }
}

.list-view-table-text {
  @include list-view-small-text;
  font-weight: normal;
  border: none;
  padding-left: 1rem;
}

.list-view-table-row:nth-child(odd) {
  background-color: #f5f5f5;
  height: 2.5rem;
}

.list-view-table-row:nth-child(even) {
  background-color: white;
  height: 2.5rem;
}

.list-view-table-header {
  background: #0076c0;
  color: #ffffff;
  border: none;
  text-align: left;
  // padding-bottom: 1rem;
}

.list-view-table-padding-left {
  padding-left: 1rem;
}

.list-view-table-padding-right {
  padding-right: 1rem;
}

.list-view-table-scope-padding {
  @include respond-below("laptop") {
    padding-right: 5rem;
  }
}

.list-view-header-text {
  @include label-text;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
}

.list-view-second-header-element {
  @include label-text;
}

.list-view-table-border {
  border: none;
  @include list-view-small-text;
}

.list-view-table-event-text-high {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #c91010;
  font-weight: normal;
}

.list-view-table-event-text-medium {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #b85e0b;
  font-weight: normal;
}

.list-view-table-event-text-low {
  @include list-view-small-text;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: normal;
}

.list-view-table-advisory-link {
  transform: scale(0.8);
}

.list-view-table-delay-trend-link {
  transform: scale(0.8);
}

.list-view-table-scope-link {
  transform: scale(0.8);
}

.list-view-link-align {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  z-index: 9000;
}

.list-view-table-fixed {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    left: 0;
    position: sticky;
    z-index: 9998;
    padding-right: 0.3rem;
  }
}

.list-view-table-fixed-second {
  @media screen and (min-width: 300px) and (max-width: 990px) {
    position: sticky;
    z-index: 9998;
    left: 4.3rem;
  }

  @media screen and (min-width: 300px) and (max-width: 650px) {
    left: 3.85rem;
    padding-left: 1rem;
  }
}

.list-view-header-search {
  position: relative;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  width: 80%;
  min-width: 9rem;
  height: 2.5rem;
}

.list-view-table-search-field {
  @include list-view-small-text;
  position: absolute;
  border-radius: 0.7rem !important;
  border: 0rem !important;
  top: 0.1rem;
  right: 0rem;
  text-align: left;
  width: 105% !important;
}

.search-icon {
  font-size: 1.3rem;
  color: #646464;
  position: absolute;
  top: 20%;
  right: 0.5rem;
}

::placeholder {
  color: #b0b0b0;
  font-style: italic;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b0b0b0;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b0b0b0;
  font-style: italic;
}

.filter-subheader {
  @include form-title;
  padding-bottom: 0.25rem;
  padding-left: 0.3rem;
}

.filter-label {
  padding-left: 0.3rem;
  padding-bottom: 0.75rem;
  width: 100%;
}

input[type="checkbox"] {
  accent-color: gray;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

.table-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end !important;

  @include respond-below("tablet"){
    justify-content: space-around!important;
  }
}

.change-page-button:hover {
  cursor: pointer;
}

.change-page-button {
  color: #003e7e;
  border: none;
  background: none;
  display: flex;

  font-size: 1.75rem;
  margin: 0.5rem 2.5rem 0.5rem 6rem;

  @include respond-below("tablet"){
    margin: 0.5rem 0rem 0.5rem 0rem;
  }
}

.change-page-button-disabled {
  color: #d5d5d5;
  border: none;
  background: none;
  display: flex;

  font-size: 1.75rem;
  margin: 0.5rem 2.5rem 0.5rem 6rem;
  @include respond-below("tablet"){
    margin: 0.5rem 0rem 0.5rem 0rem;
  }
}

.airport-filter-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 0.3rem;
  width: 97%;
}

.airport-filter {
  display: flex;
  margin-bottom: 0.2rem;
  flex-flow: nowrap;
  margin-right: 0.5rem;
  width: fit-content;
}

.info-icon-text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0.5rem 11.5rem 0.5rem 0rem;}

.table-info-container {
  display: flex;
  align-items: flex-start;
}

.info-text {
  display: flex;
  flex-direction: column;
}

.description-container {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.5rem 0.7rem 1rem;
}

.export-button {
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  text-decoration: none;
  color: #003e7e;
  padding: 0.5rem 1rem;
}

.export-icon {
  color: #003e7e;
}

.info-icon {
  display: flex;
  color: #b85e0b;
}

.select-dropdown {
  margin-top: -0.4rem;
  width: 7rem;
  text-align: left;
  vertical-align: middle;
}

.description-container h4 + text {
  border-top: 1px solid #cfcfcf;
  padding-top: 0.75rem;
  margin-top: 0.2rem;
}

.description-container text + text {
  padding-top: 0.4rem;
}

.summary-text {
  @include section-title;
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin: 0 auto;
  padding-left: 7rem;
}

.report-center-search{
  @include respond-below("tablet") {
    position: absolute;
    left: 67.67%;
    transform: translateY(6rem);
    // transform: translate(-50%,6rem);

  }
}

.table-export-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search-containe{
  margin-top: 10%;
}

.new-input-search-field {
  @include list-view-small-text;
  position: absolute;
  border-radius: 0.7rem;
  right: 0rem;
  text-align: left;
  min-width: 9rem;
  border: #b0b0b0 solid;
  border: solid #b0b0b0 !important;
  background-color: #ffffff;
  padding: 0.4rem 1.2rem 0.4rem 0.4rem;
  width: 10rem;

}