@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.confirmation-modal {
  display: flex;
  flex-direction: column;
  // min-width: 26rem;
  width: 26rem;
  // align-items: center;
  position: absolute;
  top: 25%;
  background-color: white;
  padding: 0.8rem 0.5rem;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
  border-radius: 0.4rem;
  outline: 0;

  // @include respond-between("tablet", "laptop") {
  //   top: 25%;
  //   left: 30%;
  // }

  @include respond-below("tablet") {
    width: 90%;
  }
}

.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.75);
  display: flex;
  overflow-y: scroll;
  justify-content: center;
}

.confirmation-modal-close {
  background: none;
  border: none;
  align-self: flex-end;
  color: #344da6;
  font-size: 1.2rem;
  padding-right: 0.5rem;
}

.confirmation-modal-close:hover {
  cursor: pointer;
}

.reservation-button {
  @include section-title;

  background: none;
  border: none;
  color: #344da6;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}

.reservation-button:hover {
  cursor: pointer;
}

.new-icon {
  padding-right: 0.25rem;
}

.trashcan-icon {
  color: #bd4e4e;
  width: 2.5rem;
  height: 2.5rem;
  padding-right: 0.25rem;
}

.modify-icon {
  color: #b2a341;
  width: 2.5rem;
  height: 2.5rem;
  padding-right: 0.25rem;
}

.confirmation-modal-title {
  @include form-title;

  padding-bottom: 1rem;
}

.confirmation-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-form-label-text {
  @include form-field-name;

  padding: 0.3rem 0.3rem 0.3rem 0rem;
  text-align: left;
  width: 100%;
  word-break: break-word;
}

.confirmation-reserve-num {
  padding-right: 1rem;
  white-space: nowrap;
  font-weight: bold;
  font-size: 0.9rem;
}

.modal-field {
  align-items: left;
  display: inline;
  text-align: left;
  margin-left: 0.5rem;
}

.confirmation-numb {
  background-color: #0076c0;
  font-size: small;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 0.3rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.confirmation-header {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  width: 90%;
  margin-top: -1rem;
}

.green-check {
  color: #5cac60;
  width: 2.5rem;
  height: 2.5rem;
  padding-right: 0.25rem;
}

.confirmation-form-fields {
  display: flex;
  word-wrap: break-word;
}

h2 {
  @include form-title;
  text-align: center;
}

Modal {
  align-items: center;
}

h6 {
  font-weight: bold;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.confirmation-email-check {
  display: flex;
  align-items: center;
  padding-top: 1rem;
}

.confirmation-email-text {
  padding-top: 0rem;
}

.envelope-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.email-error-message {
  @include small-text;
  color: #ff0000;
  padding: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.confirmation-details-header{
  text-align: left!important;
}

.confirmation-header-text{
  font-size: 1.5rem;

  @include respond-below("tablet"){
    font-size: 1rem;
  }
}