@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.reservation-graph-container {
    display: flex;
    flex-direction: column;
    height: 60%;
    align-items: center;
    margin: 1rem 0.5%;
    background: white;
    padding: 0.8rem 0.5rem;
    box-shadow: 8px 8px 8px rgb(0 0 0 / 25%);
    border-radius: 0.4rem;
  
    // @include respond-between("tablet", "laptop") {
    //   top: 25%;
    //   left: 30%;
    // }
  
    @include respond-below("tablet") {
      top: 10%;
      height: 85%;
      width: 96%;
    }
  }