@import "src/helpers/style/media-queries";
@import "src/helpers/text";

.flyout-menu-closed {
  display: none;
  width: 0;
  left: 100%;
}

.flyout-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
  background: #ffffff;
  z-index: 9999;

  color: #003e7e;

  transition: left 0.5s ease;

  touch-action: none;
}

.flyout-close-text {
  @include body-text;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
}

.flyout-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flyout-links {
  display: flex;
  flex-direction: column;

  > * {
    padding-top: 1rem;
  }
}

.flyout-title {
  @include site-title;
  color: #000000;
  font-weight: normal;
  margin-left: 0.5rem;
}

.flyout-external-link {
  @include site-title;
  text-decoration: none;
  font-size: 1.125rem;
  color: inherit;

  @include respond-above("desktop") {
    padding: 1.32rem;
  }

  @include respond-below("desktop") {
    padding: 1.4rem;
  }

  @include respond-below("laptop") {
    padding: 0.5rem;
  }
}
